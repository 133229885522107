import { ValidationResult, ValidationError, ValidationWarning, ErrorCode } from '../types/validation';
import { messages } from '../translations/messages';
import { XRechnungSchemaValidator } from './XRechnungSchemaValidator';

interface BaseValidator<T> {
  type: string;
  validate: (value: T) => boolean;
}

interface StringValidator extends BaseValidator<string> {
  type: 'string';
}

interface ElementValidator extends BaseValidator<Element> {
  type: 'element';
}

interface ElementArrayValidator extends BaseValidator<Element[]> {
  type: 'elementArray';
}

type ValidatorType = StringValidator | ElementValidator | ElementArrayValidator;

interface FieldConfig {
  tag: string;
  label: string;
  formats: {
    UBL?: {
      path: string;
      validator?: ValidatorType;
    };
    CII?: {
      path: string;
      validator?: ValidatorType;
    };
  };
  required: boolean;
}

interface ValidationFieldResult {
  elements: Element[];
  currentElement: Element | Document;
  found: boolean;
}

type DocumentFormat = 'UBL' | 'CII';

export class ValidationService {
  private static readonly NAMESPACES = {
    UBL: {
      cbc: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
      cac: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2'
    },
    CII: {
      rsm: 'urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100',
      ram: 'urn:un:unece:uncefact:data:standard:ReusableAggregateBusinessInformationEntity:100',
      udt: 'urn:un:unece:uncefact:data:standard:UnqualifiedDataType:100'
    }
  };

  private static readonly FIELD_CONFIGS: FieldConfig[] = [
    {
      tag: 'ID',
      label: 'Rechnungsnummer',
      formats: {
        UBL: {
          path: 'cbc:ID',
          validator: {
            type: 'string',
            validate: value => value.length > 0
          }
        },
        CII: {
          path: 'rsm:ExchangedDocument/ram:ID',
          validator: {
            type: 'string',
            validate: value => value.length > 0
          }
        }
      },
      required: true
    },
    // ... Add other field configs following the same pattern
  ];

  static async validateXRechnung(file: File): Promise<ValidationResult> {
    try {
      const content = await file.text();
      const xmlDoc = this.parseXML(content);
      const validationResult = await this.validateDocument(xmlDoc);

      if (validationResult.isValid) {
        const schemaErrors = await XRechnungSchemaValidator.validate(content);
        validationResult.errors.push(...schemaErrors);
        validationResult.isValid = schemaErrors.length === 0;
        validationResult.hasCriticalErrors = validationResult.errors.some(e => e.severity === 'CRITICAL');
      }

      return validationResult;
    } catch (error) {
      return this.handleValidationError(error);
    }
  }

  private static isCIIFormat(xmlDoc: Document): boolean {
    return xmlDoc.documentElement.namespaceURI === this.NAMESPACES.CII.rsm;
  }

  public static validateDate(dateStr: string): boolean {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
    const ublDatePattern = /^\d{8}$/;
    
    if (!dateStr || (!isoDatePattern.test(dateStr) && !ublDatePattern.test(dateStr))) {
      return false;
    }
    
    return true;
  }

  public static parseXML(content: string): Document {
    const parser = new DOMParser();
    return parser.parseFromString(content, "application/xml");
  }

  private static createErrorResult(code: ErrorCode, message: string): ValidationResult {
    return {
      isValid: false,
      errors: [{
        code,
        message,
        location: 'document',
        severity: 'CRITICAL'
      }],
      warnings: []
    };
  }

  public static handleValidationError(error: unknown): ValidationResult {
    console.error('Validation error:', error);
    
    return {
      isValid: false,
      errors: [{
        code: 'PROCESSING_ERROR',
        message: error instanceof Error 
          ? error.message 
          : 'Ein unerwarteter Fehler ist aufgetreten',
        location: 'document',
        severity: 'CRITICAL',
        suggestion: 'Bitte überprüfen Sie das Dokument und versuchen Sie es erneut, oder verwenden Sie sich an Ihre Lieferant.'
      }],
      warnings: [],
      hasCriticalErrors: true
    };
  }

  private static validateAddress(addressNode: Element): boolean {
    // Check each required field individually
    const streetName = addressNode.querySelector('StreetName')?.textContent?.trim();
    const cityName = addressNode.querySelector('CityName')?.textContent?.trim();
    const postalZone = addressNode.querySelector('PostalZone')?.textContent?.trim();
    
    // For Country/IdentificationCode, we need to get Country first, then IdentificationCode
    const country = addressNode.querySelector('Country');
    const countryCode = country?.querySelector('IdentificationCode')?.textContent?.trim();

    return !!(streetName && cityName && postalZone && countryCode);
  }

  private static validatePaymentInfo(paymentNode: Element): boolean {
    const paymentMeansCode = paymentNode.querySelector('PaymentMeansCode')?.textContent;
    const financialAccountId = paymentNode.querySelector('PayeeFinancialAccount')
      ?.querySelector('ID')?.textContent;

    return !!(paymentMeansCode && financialAccountId);
  }

  private static validateInvoiceLine(lineNode: Element): boolean {
    // Get all possible namespace variations with proper namespace handling
    const getElementContent = (tagName: string): string | undefined => {
      // Try with cbc: namespace first (most common for basic components)
      let element = lineNode.getElementsByTagNameNS("urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2", tagName)[0];
      if (!element) {
        // Fallback to cac: namespace (for aggregate components)
        element = lineNode.getElementsByTagNameNS("urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2", tagName)[0];
      }
      if (!element) {
        // Final fallback to non-namespaced
        element = lineNode.getElementsByTagName(tagName)[0];
      }
      
      return element?.textContent?.trim();
    };

    const quantity = getElementContent('InvoicedQuantity');
    const amount = getElementContent('LineExtensionAmount');
    
    // Validate numeric values
    const isValidNumber = (value: string | undefined): boolean => {
      if (!value) return false;
      const num = parseFloat(value);
      return !isNaN(num) && num >= 0;
    };

    return isValidNumber(quantity) && isValidNumber(amount);
  }

  private static validateTaxInfo(taxNode: Element): boolean {
    // Check main TaxAmount with namespace support
    const taxAmount = taxNode.getElementsByTagName('cbc:TaxAmount')[0]?.textContent ||
                     taxNode.getElementsByTagName('TaxAmount')[0]?.textContent;
    if (!taxAmount) return false;

    // Check TaxSubtotal elements
    const taxSubtotals = taxNode.getElementsByTagName('cac:TaxSubtotal').length > 0 ?
                        taxNode.getElementsByTagName('cac:TaxSubtotal') :
                        taxNode.getElementsByTagName('TaxSubtotal');
    
    if (taxSubtotals.length === 0) return false;

    return Array.from(taxSubtotals).every(subtotal => {
      const taxableAmount = subtotal.getElementsByTagName('cbc:TaxableAmount')[0]?.textContent ||
                           subtotal.getElementsByTagName('TaxableAmount')[0]?.textContent;
      const subTaxAmount = subtotal.getElementsByTagName('cbc:TaxAmount')[0]?.textContent ||
                          subtotal.getElementsByTagName('TaxAmount')[0]?.textContent;
      const categoryId = subtotal.getElementsByTagName('cbc:ID')[0]?.textContent ||
                        subtotal.getElementsByTagName('ID')[0]?.textContent;

      return !!(taxableAmount && subTaxAmount && categoryId);
    });
  }

  private static validateTotalAmount(totalNode: Element): boolean {
    const requiredAmounts = [
      'LineExtensionAmount',
      'TaxExclusiveAmount',
      'TaxInclusiveAmount',
      'PayableAmount'
    ];
    
    return requiredAmounts.every(field => {
      const elements = totalNode.getElementsByTagName(`cbc:${field}`).length > 0 ?
                      totalNode.getElementsByTagName(`cbc:${field}`) :
                      totalNode.getElementsByTagName(field);
      
      if (elements.length === 0) return false;
      
      const content = elements[0].textContent?.trim() ?? '';
      return content.length > 0 && !isNaN(parseFloat(content));
    });
  }

  private static validateFieldValue(
    element: Element,
    validator: ValidatorType,
    fieldConfig: FieldConfig
  ): boolean {
    try {
      switch (validator.type) {
        case 'string':
          return validator.validate(element.textContent || '');
        case 'element':
          return validator.validate(element);
        case 'elementArray':
          const elements = Array.from(element.children);
          return validator.validate(elements);
        default:
          throw new Error(`Unknown validator type: ${(validator as any).type}`);
      }
    } catch (error) {
      return false;
    }
  }

  private static addValidationError(
    errors: ValidationError[],
    field: FieldConfig,
    code: ErrorCode,
    error?: Error
  ): void {
    const formatConfig = field.formats.CII || field.formats.UBL;
    const location = formatConfig?.path || field.tag;
                
    errors.push({
      code,
      message: this.getErrorMessage(code, field.label, error),
      location,
      severity: 'CRITICAL',
      suggestion: error ? 'Bitte überprüfen Sie das Dokument und versuchen Sie es erneut.' : undefined
    });
  }

  private static getErrorMessage(code: ErrorCode, fieldLabel: string, error?: Error): string {
    switch (code) {
      case 'MISSING_REQUIRED':
        return `Pflichtfeld fehlt: ${fieldLabel}`;
      case 'INVALID_FORMAT':
        return `Ungültiges Format: ${fieldLabel}`;
      case 'INVALID_STRUCTURE':
        return `Ungültige Struktur: ${fieldLabel}`;
      case 'PROCESSING_ERROR':
        return `Verarbeitungsfehler bei ${fieldLabel}: ${error?.message || 'Unbekannter Fehler'}`;
      default:
        return `Unbekannter Fehler bei ${fieldLabel}`;
    }
  }

  public static validateXMLStructure(xmlDoc: Document): ValidationError[] {
    const errors: ValidationError[] = [];
    const parseErrors = xmlDoc.getElementsByTagName("parsererror");
    
    if (parseErrors.length > 0) {
      errors.push({
        code: 'PARSE_ERROR',
        message: 'XML-Parsing fehlgeschlagen',
        location: 'document',
        severity: 'CRITICAL'
      });
    }
    
    return errors;
  }

  public static validateBusinessRules(xmlDoc: Document): ValidationError[] {
    const errors: ValidationError[] = [];
    // Implement business rule validation
    return errors;
  }

  private static validateFieldPath(
    xmlDoc: Document, 
    path: string, 
    namespaces: Record<string, string>
  ): ValidationFieldResult {
    const pathParts = path.split('/');
    let currentElement: Element | Document = xmlDoc;
    const elements: Element[] = [];
    let found = false;

    for (const part of pathParts) {
      if (!part) continue;

      const [prefix, localName] = part.split(':');
      if (!prefix || !localName) {
        throw new Error('Invalid namespace format');
      }

      const namespace = namespaces[prefix];
      if (!namespace) {
        throw new Error(`Unknown namespace prefix: ${prefix}`);
      }

      // Explicitly type the newElements array
      const newElements: Element[] = Array.from(
        currentElement.getElementsByTagNameNS(namespace, localName)
      );
      
      if (newElements.length === 0) {
        return { elements: [], currentElement, found: false };
      }

      currentElement = newElements[0];
      if (currentElement instanceof Element) {
        elements.push(currentElement);
        found = true;
      }
    }

    return { elements, currentElement, found };
  }

  public static async validateDocument(xmlDoc: Document): Promise<ValidationResult> {
    const errors: ValidationError[] = [];
    const format = this.detectFormat(xmlDoc);
    
    if (!format) {
      return this.createErrorResult('INVALID_ROOT', 'Ungültiges Dokumentformat');
    }

    const namespaces = this.NAMESPACES[format];
    
    for (const fieldConfig of this.FIELD_CONFIGS) {
      const formatConfig = fieldConfig.formats[format];
      if (!formatConfig) continue;

      try {
        const result = this.validateFieldPath(xmlDoc, formatConfig.path, namespaces);
        if (!result.found && fieldConfig.required) {
          this.addValidationError(errors, fieldConfig, 'MISSING_REQUIRED');
          continue;
        }

        if (formatConfig.validator) {
          const value = result.elements[0];
          if (!this.validateValue(value, formatConfig.validator, fieldConfig)) {
            this.addValidationError(errors, fieldConfig, 'INVALID_FORMAT');
          }
        }
      } catch (error) {
        this.addValidationError(errors, fieldConfig, 'PROCESSING_ERROR', 
          error instanceof Error ? error : new Error(String(error)));
      }
    }

    return {
      isValid: errors.length === 0,
      errors,
      warnings: errors.filter(e => e.severity === 'WARNING'),
      hasCriticalErrors: errors.some(e => e.severity === 'CRITICAL')
    };
  }

  private static async validateField(
    doc: Document,
    fieldConfig: FieldConfig,
    formatConfig: { path: string; validator?: ValidatorType },
    namespaces: Record<string, string>
  ): Promise<ValidationResult> {
    const elements = this.queryElements(doc, formatConfig.path, namespaces);
    
    if (elements.length === 0 && fieldConfig.required) {
      return {
        isValid: false,
        errors: [this.createValidationError('MISSING_REQUIRED', fieldConfig)],
        warnings: []
      };
    }

    if (!formatConfig.validator) {
      return { isValid: true, errors: [], warnings: [] };
    }

    const validationErrors = elements.flatMap(element => 
      this.validateValue(element, formatConfig.validator!, fieldConfig)
    );

    return {
      isValid: validationErrors.length === 0,
      errors: validationErrors,
      warnings: []
    };
  }

  private static validateValue(
    element: Element,
    validator: ValidatorType,
    fieldConfig: FieldConfig
  ): ValidationError[] {
    try {
      let isValid = false;

      switch (validator.type) {
        case 'string':
          isValid = validator.validate(element.textContent || '');
          break;
        case 'element':
          isValid = validator.validate(element);
          break;
        case 'elementArray':
          const elements = Array.from(element.children);
          isValid = validator.validate(elements);
          break;
      }

      return isValid ? [] : [
        this.createValidationError('INVALID_FORMAT', fieldConfig)
      ];
    } catch (error) {
      return [this.createValidationError('PROCESSING_ERROR', fieldConfig, 
        error instanceof Error ? error.message : 'Unknown error')];
    }
  }

  private static queryElements(
    doc: Document,
    path: string,
    namespaces: Record<string, string>
  ): Element[] {
    const parts = path.split('/');
    let current: Element | Document = doc;
    const elements: Element[] = [];
    
    for (const part of parts) {
      if (!part) continue;

      const [prefix, localName] = part.split(':');
      if (!prefix || !localName) {
        throw new Error('Invalid namespace format');
      }

      const namespace = namespaces[prefix];
      if (!namespace) {
        throw new Error(`Unknown namespace prefix: ${prefix}`);
      }

      // Convert HTMLCollection to Array immediately
      const newElements: Element[] = Array.from(
        current.getElementsByTagNameNS(namespace, localName)
      );
      
      if (newElements.length === 0) {
        return [];
      }

      current = newElements[0];
      if (current instanceof Element) {
        elements.push(current);
      }
    }

    return elements;
  }

  private static detectFormat(xmlDoc: Document): DocumentFormat | null {
    const rootElement = xmlDoc.documentElement;
    const rootNamespace = rootElement.namespaceURI;
    const rootLocalName = rootElement.localName;

    // Check for CII format
    if (rootNamespace === this.NAMESPACES.CII.rsm) {
      return 'CII';
    }

    // Check for UBL format (both Invoice and CreditNote)
    if (rootNamespace === 'urn:oasis:names:specification:ubl:schema:xsd:Invoice-2' ||
        rootNamespace === 'urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2') {
      return 'UBL';
    }

    return null;
  }

  private static createValidationError(
    code: ErrorCode,
    fieldConfig: FieldConfig,
    message?: string
  ): ValidationError {
    return {
      code,
      message: message || this.getErrorMessage(code, fieldConfig.label),
      location: fieldConfig.tag,
      severity: 'CRITICAL',
      field: fieldConfig.tag
    };
  }
} 