import { 
  XRechnungData, 
  Attachment,
  Party,
  Address,
  Contact,
  BankAccount,
  PaymentMeans,
  PaymentTerms,
  InvoiceItem,
  PaymentReference,
  ValidationResult
} from '../types';
import { BaseXRechnungParser } from './BaseXRechnungParser';

export class UBLXRechnungParser extends BaseXRechnungParser {
  private documentType: string;
  private readonly LINE_TYPES = ['InvoiceLine', 'CreditNoteLine'] as const;
  
  constructor(xmlContent: string) {
    super(xmlContent);
    this.documentType = this.determineDocumentType();
  }

  private determineDocumentType(): string {
    const root = this.xmlDoc.documentElement;
    const localName = root.localName || root.tagName.split(':').pop();
    
    if ((localName === 'Invoice' || root.tagName === 'ubl:Invoice') && 
        (root.namespaceURI === this.namespaces.default || 
         root.namespaceURI === this.namespaces.ubl)) {
      return 'Invoice';
    }
    
    if ((localName === 'CreditNote' || root.tagName === 'ubl:CreditNote') && 
        (root.namespaceURI === this.namespaces.default || 
         root.namespaceURI === this.namespaces.ubl)) {
      return 'CreditNote';
    }
    
    throw new Error('Unknown document type');
  }

  public validate(): ValidationResult {
    // Implement UBL-specific validation
    return {
      isValid: true,
      errors: [],
      warnings: []
    };
  }

  public parse(): XRechnungData {
    return {
      id: this.getElementValue('ID'),
      documentType: this.documentType,
      invoiceNumber: this.getElementValue('ID'),
      issueDate: this.getElementValue('IssueDate'),
      dueDate: this.getElementValue('DueDate'),
      invoiceTypeCode: this.getElementValue('InvoiceTypeCode'),
      buyerReference: this.getElementValue('BuyerReference'),
      currencyCode: this.getElementValue('DocumentCurrencyCode'),
      notes: this.getNotes(),
      paymentMeans: this.parsePaymentMeans(),
      seller: this.parseSeller(),
      buyer: this.parseBuyer(),
      items: this.parseLineItems(),
      ...this.parseTotals(),
      delivery: this.parseDelivery(),
      attachments: this.parseAttachments(),
      precedingInvoiceReference: this.getElementValue('PrecedingInvoiceReference'),
      contractReference: this.getElementValue('ContractDocumentReference/ID'),
      projectReference: this.getElementValue('ProjectReference/ID'),
      paymentTerms: this.parsePaymentTerms()
    };
  }

  protected getElementValue(tagName: string): string {
    const element = this.xmlDoc.getElementsByTagName(`cbc:${tagName}`)[0];
    return element?.textContent?.trim() || '';
  }

  private parsePaymentMeans(): PaymentMeans | undefined {
    const paymentMeans = this.xmlDoc.getElementsByTagName('cac:PaymentMeans')[0];
    if (!paymentMeans) return undefined;

    const type = this.getElementFromNode(paymentMeans, 'PaymentMeansCode');
    const iban = this.getElementFromNode(paymentMeans, 'PayeeFinancialAccount/ID');
    const paymentId = this.getElementFromNode(paymentMeans, 'PaymentID');
    
    // Get payment note from PaymentTerms
    const paymentTerms = this.xmlDoc.getElementsByTagName('cac:PaymentTerms')[0];
    const note = paymentTerms ? this.getElementFromNode(paymentTerms, 'Note') : undefined;
    
    // Only filter out notes that are purely Skonto notes (don't contain regular text)
    const isValidNote = note && (!note.trim().startsWith('#SKONTO#') || note.includes(' '));
    
    const reference: PaymentReference = {
      payment: paymentId || undefined,
      invoice: this.getElementFromNode(paymentMeans, 'InvoiceReference') || undefined,
      contract: this.getElementFromNode(paymentMeans, 'ContractReference') || undefined,
      order: this.getElementFromNode(paymentMeans, 'OrderReference') || undefined
    };

    const hasReferences = Object.values(reference).some(v => v !== undefined);

    return {
      type: type || undefined,
      bankAccount: iban ? { iban } : undefined,
      reference: hasReferences ? reference : undefined,
      note: isValidNote ? note : undefined
    };
  }

  private getPaymentType(paymentMeans: Element): string {
    const code = this.getElementFromNode(paymentMeans, 'PaymentMeansCode');
    // Map payment means codes to readable types according to official UNECE codes
    const types: { [key: string]: string } = {
      '1': 'Barzahlung',
      '10': 'Barzahlung bei Lieferung',
      '20': 'Scheck',
      '30': 'Elektronische Zahlung',
      '31': 'Debitkarte',
      '42': 'Banküberweisung',
      '48': 'Kreditkarte',
      '49': 'Lastschrift',
      '50': 'Gutschrift',
      '58': 'SEPA-Überweisung',
      '59': 'SEPA-Lastschrift',
      '97': 'Aufrechnung'
    };
    return types[code] || `Zahlungsart ${code}`;
  }

  private parseBankAccount(paymentMeans: Element): BankAccount | undefined {
    const financialAccount = paymentMeans.getElementsByTagName('cac:PayeeFinancialAccount')[0];
    if (!financialAccount) return undefined;

    return {
      iban: this.getElementFromNode(financialAccount, 'ID'),
      bic: this.getElementFromNode(financialAccount, 'FinancialInstitutionBranch/ID'),
      bankName: this.getElementFromNode(financialAccount, 'FinancialInstitution/Name')
    };
  }

  private parseSeller(): Party {
    const sellerParty = this.xmlDoc.getElementsByTagName('cac:AccountingSupplierParty')[0]
      ?.getElementsByTagName('cac:Party')[0];
    
    if (!sellerParty) return this.createEmptyParty();

    return {
      name: this.getElementFromNode(sellerParty, 'PartyLegalEntity/RegistrationName') ||
            this.getElementFromNode(sellerParty, 'PartyName/Name'),
      address: this.parseAddress(sellerParty),
      taxId: this.getElementFromNode(sellerParty, 'PartyTaxScheme/CompanyID'),
      vatNumber: this.getElementFromNode(sellerParty, 'PartyTaxScheme/CompanyID'),
      contact: this.parseContactInfo(sellerParty),
      reference: this.getElementFromNode(sellerParty, 'PartyIdentification/ID')
    };
  }

  private parseBuyer(): Party {
    const buyerParty = this.xmlDoc.getElementsByTagName('cac:AccountingCustomerParty')[0]
      ?.getElementsByTagName('cac:Party')[0];
    
    if (!buyerParty) return this.createEmptyParty();

    const contact = buyerParty.getElementsByTagName('cac:Contact')[0];
    const contactInfo = contact ? {
      name: this.getElementFromNode(contact, 'cbc:Name'),
      telephone: this.getElementFromNode(contact, 'cbc:Telephone'),
      email: this.getElementFromNode(contact, 'cbc:ElectronicMail')
    } : undefined;

    return {
      name: this.getElementFromNode(buyerParty, 'PartyLegalEntity/RegistrationName') ||
            this.getElementFromNode(buyerParty, 'PartyName/Name'),
      address: this.parseAddress(buyerParty),
      taxId: this.getElementFromNode(buyerParty, 'PartyTaxScheme/CompanyID'),
      vatNumber: this.getElementFromNode(buyerParty, 'PartyTaxScheme/CompanyID'),
      contact: contactInfo,
      reference: this.getElementFromNode(buyerParty, 'PartyIdentification/ID')
    };
  }

  private parseContactInfo(partyNode: Element): Contact | undefined {
    const contactNode = partyNode.getElementsByTagName('cac:Contact')[0];
    if (!contactNode) return undefined;

    // Use explicit cbc: prefix for these elements
    const name = contactNode.getElementsByTagName('cbc:Name')[0]?.textContent?.trim();
    const telephone = contactNode.getElementsByTagName('cbc:Telephone')[0]?.textContent?.trim();
    const email = contactNode.getElementsByTagName('cbc:ElectronicMail')[0]?.textContent?.trim();

    // Only return contact if at least one field has content
    if (!name && !telephone && !email) return undefined;

    return {
      name: name || '',
      telephone: telephone || '',
      email: email || ''
    };
  }

  private createEmptyParty(): Party {
    return {
      name: '',
      address: undefined,
      taxId: '',
      vatNumber: '',
      contact: undefined,
      reference: ''
    };
  }

  private parseAddress(party: Element) {
    const address = party.getElementsByTagName('cac:PostalAddress')[0];
    if (!address) return this.createEmptyAddress();

    return {
      street: this.getElementFromNode(address, 'StreetName'),
      city: this.getElementFromNode(address, 'CityName'),
      postcode: this.getElementFromNode(address, 'PostalZone'),
      country: this.getElementFromNode(address, 'Country/Name'),
      countryCode: this.getElementFromNode(address, 'Country/IdentificationCode')
    };
  }

  private parseLineItems(): InvoiceItem[] {
    const lines = this.getLineItems();
    return lines.map(line => ({
      id: this.getElementFromNode(line, 'ID'),
      description: this.getElementFromNode(line, 'Item/Name') || 
                  this.getElementFromNode(line, 'Item/Description'),
      quantity: parseFloat(this.getElementFromNode(line, 
        this.documentType === 'Invoice' ? 'InvoicedQuantity' : 'CreditedQuantity')),
      unitCode: this.getElementFromNode(line, 
        this.documentType === 'Invoice' ? 'InvoicedQuantity/@unitCode' : 'CreditedQuantity/@unitCode'),
      unitPrice: parseFloat(this.getElementFromNode(line, 'Price/PriceAmount')),
      lineTotal: parseFloat(this.getElementFromNode(line, 'LineExtensionAmount')),
      vatRate: parseFloat(this.getElementFromNode(line, 'Item/ClassifiedTaxCategory/Percent')),
      period: this.parseInvoicePeriod(line)
    }));
  }

  private parseInvoicePeriod(node: Element) {
    const period = node.getElementsByTagName('cac:InvoicePeriod')[0];
    if (!period) return undefined;
    
    return {
      startDate: this.getElementFromNode(period, 'StartDate'),
      endDate: this.getElementFromNode(period, 'EndDate')
    };
  }

  private parseTotals() {
    const legalMonetaryTotal = this.xmlDoc.getElementsByTagName('cac:LegalMonetaryTotal')[0];
    if (!legalMonetaryTotal) return this.createEmptyTotals();

    return {
      totalAmount: parseFloat(this.getElementValue('TaxInclusiveAmount')),
      vatTotal: parseFloat(this.getElementValue('TaxTotal/TaxAmount')),
      lineExtensionAmount: parseFloat(this.getElementValue('LineExtensionAmount')),
      taxExclusiveAmount: parseFloat(this.getElementValue('TaxExclusiveAmount')),
      taxInclusiveAmount: parseFloat(this.getElementValue('TaxInclusiveAmount')),
      payableAmount: parseFloat(this.getElementValue('PayableAmount'))
    };
  }

  private parseDelivery() {
    const delivery = this.xmlDoc.getElementsByTagName('cac:Delivery')[0];
    if (!delivery) return undefined;

    return {
      deliveryDate: this.getElementValue('ActualDeliveryDate'),
      deliveryLocation: this.parseAddress(delivery)
    };
  }

  private parseAttachments(): Attachment[] {
    const attachments = this.xmlDoc.getElementsByTagName('cac:AdditionalDocumentReference');
    return Array.from(attachments).map(attachment => ({
      id: this.getElementFromNode(attachment, 'ID'),
      filename: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject/@filename'),
      mimeType: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject/@mimeCode'),
      description: this.getElementFromNode(attachment, 'DocumentDescription'),
      data: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject'),
      content: this.getElementFromNode(attachment, 'Attachment/EmbeddedDocumentBinaryObject')
    }));
  }

  private getElementFromNode(node: Element, path: string): string {
    if (!node) return '';
    
    // Handle namespaced elements
    const parts = path.split('/');
    let current = node;
    
    for (const part of parts) {
      if (!current) return '';
      
      // Handle attribute selectors
      if (part.startsWith('@')) {
        return current.getAttribute(part.substring(1)) || '';
      }
      
      // Try with and without namespace prefixes
      const variants = [
        `cbc:${part}`,
        `cac:${part}`,
        part
      ];
      
      let found = false;
      for (const variant of variants) {
        const elements = current.getElementsByTagName(variant);
        if (elements.length > 0) {
          current = elements[0];
          found = true;
          break;
        }
      }
      
      if (!found) return '';
    }
    
    return current?.textContent?.trim() || '';
  }

  private createEmptyAddress() {
    return {
      street: '',
      city: '',
      postcode: '',
      country: '',
      countryCode: ''
    };
  }

  private createEmptyTotals() {
    return {
      totalAmount: 0,
      vatTotal: 0,
      lineExtensionAmount: 0,
      taxExclusiveAmount: 0,
      taxInclusiveAmount: 0,
      payableAmount: 0
    };
  }

  private parsePaymentTerms(): PaymentTerms {
    const terms = this.xmlDoc.getElementsByTagName('cac:PaymentTerms')[0];
    if (!terms) return { note: '' };
    
    const noteElement = terms.getElementsByTagName('cbc:Note')[0];
    const noteText = noteElement?.textContent?.trim() || '';
    
    // Parse skonto terms if present
    const skontoTerms = noteText
      .split('\n')
      .map(line => line.trim())
      .filter(line => line.startsWith('#SKONTO#'))
      .map(line => {
        const parts = line.split('#').filter(Boolean);
        const days = parts.find(p => p.startsWith('TAGE='))?.split('=')[1];
        const percent = parts.find(p => p.startsWith('PROZENT='))?.split('=')[1];
        const baseAmount = parts.find(p => p.startsWith('BASISBETRAG='))?.split('=')[1];
        
        // Validate decimal format for amounts
        const validBaseAmount = baseAmount && /^\-?\d+(\.\d{1,2})?$/.test(baseAmount)
          ? parseFloat(baseAmount)
          : undefined;

        return {
          days: parseInt(days || '0'),
          percentage: parseFloat(percent || '0'),
          baseAmount: validBaseAmount
        };
      })
      .filter(term => term.days > 0 || term.percentage > 0);

    return {
      note: noteText,
      skontoTerms: skontoTerms.length > 0 ? skontoTerms : undefined
    };
  }

  private getElementByXPath(xpath: string): Element | null {
    const nsResolver = (prefix: string | null) => {
      if (!prefix) return null;
      return this.namespaces[prefix] || null;
    };
    
    const result = this.xmlDoc.evaluate(
      xpath,
      this.xmlDoc,
      nsResolver,
      XPathResult.FIRST_ORDERED_NODE_TYPE,
      null
    );
    return result.singleNodeValue as Element;
  }

  private getNotes(): string[] {
    const notes = this.xmlDoc.getElementsByTagName('cbc:Note');
    return Array.from(notes).map(note => note.textContent?.trim() || '');
  }

  private getLineItems(): Element[] {
    for (const type of this.LINE_TYPES) {
      const lines = Array.from(this.xmlDoc.getElementsByTagNameNS(this.namespaces.cac || '', type));
      if (lines.length > 0) {
        return lines;
      }
      
      // Fallback to non-namespaced lookup
      const nonNamespacedLines = Array.from(this.xmlDoc.getElementsByTagName(`cac:${type}`));
      if (nonNamespacedLines.length > 0) {
        return nonNamespacedLines;
      }
    }
    return [];
  }

  private parseTaxTotals() {
    const taxTotal = this.xmlDoc.getElementsByTagName('cac:TaxTotal')[0];
    if (!taxTotal) return { vatTotal: 0 };

    const subtotals = Array.from(taxTotal.getElementsByTagName('cac:TaxSubtotal'))
      .map(subtotal => ({
        taxableAmount: parseFloat(this.getElementFromNode(subtotal, 'TaxableAmount')),
        taxAmount: parseFloat(this.getElementFromNode(subtotal, 'TaxAmount')),
        vatCategory: this.getElementFromNode(subtotal, 'TaxCategory/ID'),
        vatPercent: parseFloat(this.getElementFromNode(subtotal, 'TaxCategory/Percent'))
      }));

    return {
      vatTotal: parseFloat(this.getElementFromNode(taxTotal, 'TaxAmount')),
      taxSubtotals: subtotals
    };
  }

  private getAmountWithCurrency(node: Element, path: string) {
    return {
      amount: parseFloat(this.getElementFromNode(node, path)),
      currency: this.getElementFromNode(node, `${path}/@currencyID`)
    };
  }
} 