import { XRechnungData } from '../types';
import { UBLXRechnungParser } from './UBLXRechnungParser';
import { CIIXRechnungParser } from './CIIXRechnungParser';
import { BaseXRechnungParser, XRechnungFormat } from './BaseXRechnungParser';

export class XRechnungService {
  static async parseXRechnung(content: string): Promise<XRechnungData> {
    try {
      const format = BaseXRechnungParser.detectFormat(content);
      const parser = this.createParser(format, content);
      return parser.parse();
    } catch (error) {
      console.error('Error parsing XRechnung:', error);
      throw new Error('Failed to parse XRechnung document');
    }
  }

  private static createParser(format: XRechnungFormat, content: string): BaseXRechnungParser {
    switch (format) {
      case 'UBL':
        return new UBLXRechnungParser(content);
      case 'CII':
        return new CIIXRechnungParser(content);
      default:
        throw new Error('Unsupported XRechnung format');
    }
  }

  static validateXRechnung(content: string) {
    const format = BaseXRechnungParser.detectFormat(content);
    const parser = this.createParser(format, content);
    return parser.validate();
  }
}
