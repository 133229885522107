import React from 'react';
import { Link } from 'react-router-dom';
import { FileX } from 'lucide-react';

export const NotFound = () => {
  return (
    <div className="min-h-[60vh] flex items-center justify-center">
      <div className="text-center">
        <FileX className="h-16 w-16 text-gray-400 mx-auto mb-4" />
        <h1 className="text-4xl font-bold text-gray-900 mb-4">404 - Seite nicht gefunden</h1>
        <p className="text-gray-600 mb-8">
          Die von Ihnen gesuchte Seite existiert leider nicht.
        </p>
        <Link
          to="/"
          className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Zurück zur Startseite
        </Link>
      </div>
    </div>
  );
};
