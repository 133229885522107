import { 
  XRechnungData, 
  ValidationResult,
  Party,
  PaymentMeans,
  PaymentTerms,
  InvoiceItem,
  Attachment,
  ValidationError
} from '../types';
import { BaseXRechnungParser } from './BaseXRechnungParser';

export class CIIXRechnungParser extends BaseXRechnungParser {
  constructor(xmlContent: string) {
    super(xmlContent);
  }

  public parse(): XRechnungData {
    return {
      id: this.getElementValue('rsm:ExchangedDocument/ram:ID'),
      documentType: 'Invoice',
      invoiceNumber: this.getElementValue('rsm:ExchangedDocument/ram:ID'),
      issueDate: this.getElementValue('rsm:ExchangedDocument/ram:IssueDateTime/udt:DateTimeString'),
      dueDate: this.getPaymentDueDate(),
      invoiceTypeCode: this.getElementValue('rsm:ExchangedDocument/ram:TypeCode'),
      currencyCode: this.getElementValue('rsm:SupplyChainTradeTransaction/ram:ApplicableHeaderTradeSettlement/ram:InvoiceCurrencyCode'),
      buyerReference: this.getElementValue('rsm:SupplyChainTradeTransaction/ram:ApplicableHeaderTradeAgreement/ram:BuyerReference'),
      notes: this.parseNotes(),
      paymentMeans: this.parsePaymentMeans(),
      seller: this.parseSeller(),
      buyer: this.parseBuyer(),
      delivery: this.parseDelivery(),
      items: this.parseLineItems(),
      ...this.parseTotals(),
      attachments: this.parseAttachments(),
      paymentTerms: this.parsePaymentTerms()
    };
  }

  public validate(): ValidationResult {
    const errors: ValidationError[] = [];
    
    // Validate required fields
    const requiredFields = [
      { path: 'rsm:ExchangedDocument/ram:ID', label: 'Rechnungsnummer' },
      { path: 'rsm:ExchangedDocument/ram:IssueDateTime/udt:DateTimeString', label: 'Rechnungsdatum' },
      { path: 'rsm:ExchangedDocument/ram:TypeCode', label: 'Rechnungstyp' },
      { path: 'rsm:SupplyChainTradeTransaction/ram:ApplicableHeaderTradeSettlement/ram:InvoiceCurrencyCode', label: 'Währung' }
    ];

    requiredFields.forEach(field => {
      const value = this.getElementValue(field.path);
      if (!value) {
        errors.push({
          code: 'MISSING_REQUIRED',
          message: `Pflichtfeld fehlt: ${field.label}`,
          location: field.path,
          severity: 'CRITICAL'
        });
      }
    });

    return {
      isValid: errors.length === 0,
      errors,
      warnings: [],
      hasCriticalErrors: errors.some(e => e.severity === 'CRITICAL')
    };
  }

  private getPaymentDueDate(): string {
    const terms = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'SpecifiedTradePaymentTerms')[0];
    return terms ? this.getElementValue('ram:DueDateDateTime/udt:DateTimeString', terms) : '';
  }
  
  private parseNotes(): string[] {
    const notes = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'IncludedNote');
    return Array.from(notes).map(note => 
      this.getElementValue('ram:Content', note as Element)
    ).filter(Boolean);
  }
  
  private parseSeller(): Party {
    const seller = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'SellerTradeParty')[0];
    if (!seller) return this.createEmptyParty();
  
    return {
      name: this.getElementValue('ram:Name', seller as Element),
      address: this.parseAddress(seller as Element),
      taxId: this.getElementValue('ram:SpecifiedTaxRegistration/ram:ID', seller as Element),
      vatNumber: this.getElementValue('ram:SpecifiedTaxRegistration/ram:ID', seller as Element),
      contact: this.parseContact(seller as Element)
    };
  }

  private parseBuyer(): Party {
    const buyer = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'BuyerTradeParty')[0];
    if (!buyer) return this.createEmptyParty();
  
    return {
      name: this.getElementValue('ram:Name', buyer as Element),
      address: this.parseAddress(buyer as Element),
      taxId: this.getElementValue('ram:SpecifiedTaxRegistration/ram:ID', buyer as Element),
      vatNumber: this.getElementValue('ram:SpecifiedTaxRegistration/ram:ID', buyer as Element),
      contact: this.parseContact(buyer as Element)
    };
  }
  
  private parseAddress(partyNode: Element) {
    const address = partyNode.getElementsByTagNameNS(this.namespaces.ram, 'PostalTradeAddress')[0];
    if (!address) return undefined;
  
    return {
      street: this.getElementValue('ram:LineOne', address),
      city: this.getElementValue('ram:CityName', address),
      postalCode: this.getElementValue('ram:PostcodeCode', address),
      country: this.getElementValue('ram:CountryID', address)
    };
  }
  
  private parseContact(partyNode: Element) {
    const contact = partyNode.getElementsByTagNameNS(this.namespaces.ram, 'DefinedTradeContact')[0];
    if (!contact) return undefined;
  
    return {
      name: this.getElementValue('ram:PersonName', contact),
      telephone: this.getElementValue('ram:TelephoneUniversalCommunication/ram:CompleteNumber', contact),
      email: this.getElementValue('ram:EmailURIUniversalCommunication/ram:URIID', contact)
    };
  }
  
  private parsePaymentMeans(): PaymentMeans | undefined {
    const paymentMeans = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'SpecifiedTradeSettlementPaymentMeans');
    if (!paymentMeans.length) return undefined;

    const ibans = Array.from(paymentMeans).map(means => 
      this.getElementValue('ram:PayeePartyCreditorFinancialAccount/ram:IBANID', means as Element)
    ).filter(Boolean);

    const type = this.getElementValue('ram:TypeCode', paymentMeans[0] as Element);

    return {
      type,
      bankAccount: ibans.length > 0 ? { iban: ibans[0] } : undefined,
      additionalBankAccounts: ibans.slice(1).map(iban => ({ iban }))
    };
  }
  
  private parseLineItems(): InvoiceItem[] {
    const items = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'IncludedSupplyChainTradeLineItem');
    return Array.from(items).map(item => ({
      id: this.getElementValue('ram:AssociatedDocumentLineDocument/ram:LineID', item),
      name: this.getElementValue('ram:SpecifiedTradeProduct/ram:Name', item),
      description: this.getElementValue('ram:SpecifiedTradeProduct/ram:Description', item),
      sellerAssignedID: this.getElementValue('ram:SpecifiedTradeProduct/ram:SellerAssignedID', item),
      quantity: parseFloat(this.getElementValue('ram:SpecifiedLineTradeDelivery/ram:BilledQuantity', item)) || 0,
      unitCode: this.getElementValue('ram:SpecifiedLineTradeDelivery/ram:BilledQuantity/@unitCode', item),
      unitPrice: parseFloat(this.getElementValue('ram:SpecifiedLineTradeAgreement/ram:NetPriceProductTradePrice/ram:ChargeAmount', item)) || 0,
      totalAmount: parseFloat(this.getElementValue('ram:SpecifiedLineTradeSettlement/ram:SpecifiedTradeSettlementLineMonetarySummation/ram:LineTotalAmount', item)) || 0,
      lineTotal: parseFloat(this.getElementValue('ram:SpecifiedLineTradeSettlement/ram:SpecifiedTradeSettlementLineMonetarySummation/ram:LineTotalAmount', item)) || 0,
      taxCategory: this.getElementValue('ram:SpecifiedLineTradeSettlement/ram:ApplicableTradeTax/ram:CategoryCode', item),
      vatRate: parseFloat(this.getElementValue('ram:SpecifiedLineTradeSettlement/ram:ApplicableTradeTax/ram:RateApplicablePercent', item)) || 0,
      notes: this.parseLineNotes(item)
    }));
  }
  
  private parseLineNotes(item: Element): string[] {
    const notes = item.getElementsByTagNameNS(this.namespaces.ram, 'IncludedNote');
    return Array.from(notes)
      .map(note => this.getElementValue('ram:Content', note))
      .filter(Boolean);
  }
  
  private parseTotals() {
    const settlement = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'ApplicableHeaderTradeSettlement')[0];
    if (!settlement) return {
      lineExtensionAmount: 0,
      taxExclusiveAmount: 0,
      taxInclusiveAmount: 0,
      payableAmount: 0,
      totalAmount: 0,
      vatTotal: 0
    };
  
    const taxTotals = this.parseTaxTotals();
    const grandTotal = parseFloat(this.getElementValue('ram:GrandTotalAmount', settlement)) || 0;
  
    return {
      lineExtensionAmount: parseFloat(this.getElementValue('ram:LineTotalAmount', settlement)) || 0,
      taxExclusiveAmount: parseFloat(this.getElementValue('ram:TaxBasisTotalAmount', settlement)) || 0,
      taxInclusiveAmount: grandTotal,
      payableAmount: parseFloat(this.getElementValue('ram:DuePayableAmount', settlement)) || 0,
      totalAmount: grandTotal,
      vatTotal: taxTotals.vatTotal
    };
  }
  
  private parseDelivery() {
    const delivery = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'ApplicableHeaderTradeDelivery')[0];
    if (!delivery) return undefined;
  
    const shipToParty = delivery.getElementsByTagNameNS(this.namespaces.ram, 'ShipToTradeParty')[0];
    return {
      deliveryDate: this.getElementValue('ram:ActualDeliverySupplyChainEvent/ram:OccurrenceDateTime/udt:DateTimeString', delivery),
      deliveryLocation: shipToParty ? this.parseAddress(shipToParty) : undefined
    };
  }
  
  private parseAttachments(): Attachment[] {
    const attachments = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'AdditionalReferencedDocument');
    return Array.from(attachments).map(attachment => ({
      filename: this.getElementValue('ram:Name', attachment),
      mimeType: this.getElementValue('ram:AttachmentBinaryObject/@mimeCode', attachment),
      content: this.getElementValue('ram:AttachmentBinaryObject', attachment)
    }));
  }
  
  private parsePaymentTerms(): PaymentTerms | undefined {
    const terms = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'SpecifiedTradePaymentTerms')[0];
    if (!terms) return undefined;

    const description = this.getElementValue('ram:Description', terms);
    if (!description) return undefined;

    const skontoTerms: { days: number; percentage: number; baseAmount?: number }[] = [];

    // Parse skonto information from description if it contains skonto terms
    if (description.includes('#SKONTO#')) {
      const skontoLines = description.split('\n').map(line => line.trim()).filter(Boolean);
      
      for (const line of skontoLines) {
        if (line.startsWith('#SKONTO#')) {
          const match = {
            days: line.match(/TAGE=(\d+)/)?.[1],
            percent: line.match(/PROZENT=(\d+\.?\d*)/)?.[1],
            baseAmount: line.match(/BASISBETRAG=(-?\d+\.?\d*)/)?.[1]
          };

          if (match.days && match.percent) {
            skontoTerms.push({
              days: parseInt(match.days),
              percentage: parseFloat(match.percent),
              ...(match.baseAmount && { baseAmount: parseFloat(match.baseAmount) })
            });
          }
        }
      }
    }

    return {
      note: description,
      skontoTerms: skontoTerms.length > 0 ? skontoTerms : undefined
    };
  }
  
  private parseTaxTotals() {
    const taxTotal = this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'ApplicableTradeTax')[0];
    if (!taxTotal) return { vatTotal: 0 };

    const subtotals = Array.from(this.xmlDoc.getElementsByTagNameNS(this.namespaces.ram, 'ApplicableTradeTax'))
      .map(subtotal => ({
        taxableAmount: parseFloat(this.getElementValue('ram:BasisAmount', subtotal)) || 0,
        taxAmount: parseFloat(this.getElementValue('ram:CalculatedAmount', subtotal)) || 0,
        vatCategory: this.getElementValue('ram:CategoryCode', subtotal),
        vatPercent: parseFloat(this.getElementValue('ram:RateApplicablePercent', subtotal)) || 0
      }));

    return {
      vatTotal: parseFloat(this.getElementValue('ram:CalculatedAmount', taxTotal)) || 0,
      taxSubtotals: subtotals
    };
  }

  private createEmptyParty(): Party {
    return {
      name: '',
      address: {
        street: '',
        city: '',
        postcode: '',
        country: ''
      },
      taxId: '',
      vatNumber: '',
      contact: {
        name: '',
        telephone: '',
        email: ''
      }
    };
  }
}
