import React from 'react';
import { Helmet } from 'react-helmet';

interface SEOProps {
  title: string;
  description: string;
  canonicalUrl?: string;
  type?: string;
  faqSchema?: {
    questions: Array<{
      question: string;
      answer: string;
    }>;
  };
  image?: string;
}

export const SEO = ({ 
  title, 
  description, 
  canonicalUrl, 
  type = 'website',
  faqSchema,
  image = 'https://xrechnungzentrale.de/assets/og-image.png' // default OG image
}: SEOProps) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content="XRechnung, E-Rechnung, ZUGFeRD, XML Rechnung, Elektronische Rechnung, B2B Rechnung" />
      
      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content={type} />
      {canonicalUrl && <meta property="og:url" content={canonicalUrl} />}
      <meta property="og:image" content={image} />
      
      {/* Twitter Card */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
      
      {/* Canonical URL */}
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      
      {/* Schema.org markup */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': type,
          name: title,
          description: description,
          ...(faqSchema && {
            mainEntity: {
              '@type': 'FAQPage',
              mainEntity: faqSchema.questions.map(q => ({
                '@type': 'Question',
                name: q.question,
                acceptedAnswer: {
                  '@type': 'Answer',
                  text: q.answer
                }
              }))
            }
          })
        })}}
      />
    </Helmet>
  );
}; 