import { ErrorCode, ValidationError } from '../types/validation';
import { DOMParser } from 'xmldom';
import { XMLValidator } from 'fast-xml-parser';

const VALID_NAMESPACES = {
  invoice: "urn:oasis:names:specification:ubl:schema:xsd:Invoice-2",
  creditNote: "urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2"
};

export class XRechnungSchemaValidator {
  private static normalizeXml(doc: Document): Document {
    // Simple normalization without XPath
    const removeComments = (node: Node): void => {
      const childNodes = Array.from(node.childNodes);
      childNodes.forEach(child => {
        if (child.nodeType === 8) { // Comment node
          node.removeChild(child);
        } else if (child.hasChildNodes()) {
          removeComments(child);
        }
      });
    };

    removeComments(doc);
    return doc;
  }

  private static isValidXRechnung(doc: Document): boolean {
    // Check for UBL Invoice/CreditNote
    const ublValid = this.isValidUBL(doc);
    if (ublValid) return true;

    // Check for CII format
    return this.isValidCII(doc);
  }

  private static isValidUBL(doc: Document): boolean {
    const UBL_NAMESPACES = {
      invoice: 'urn:oasis:names:specification:ubl:schema:xsd:Invoice-2',
      creditNote: 'urn:oasis:names:specification:ubl:schema:xsd:CreditNote-2',
      commonBasic: 'urn:oasis:names:specification:ubl:schema:xsd:CommonBasicComponents-2',
      commonAggregate: 'urn:oasis:names:specification:ubl:schema:xsd:CommonAggregateComponents-2'
    };

    const rootElement = doc.documentElement;
    
    // Check if it's an Invoice
    if (rootElement.localName === 'Invoice' && 
        rootElement.namespaceURI === UBL_NAMESPACES.invoice) {
      return true;
    }

    // Check if it's a CreditNote
    if (rootElement.localName === 'CreditNote' && 
        rootElement.namespaceURI === UBL_NAMESPACES.creditNote) {
      return true;
    }

    // Additional check for documents with prefixes
    const hasValidInvoice = Array.from(doc.getElementsByTagNameNS(UBL_NAMESPACES.invoice, 'Invoice')).length > 0;
    const hasValidCreditNote = Array.from(doc.getElementsByTagNameNS(UBL_NAMESPACES.creditNote, 'CreditNote')).length > 0;

    return hasValidInvoice || hasValidCreditNote;
  }

  private static isValidCII(doc: Document): boolean {
    const CII_NAMESPACE = "urn:un:unece:uncefact:data:standard:CrossIndustryInvoice:100";
    const rootElement = doc.documentElement;
    
    // Check if root element is CrossIndustryInvoice with correct namespace
    if (rootElement.localName === 'CrossIndustryInvoice' && 
        (rootElement.namespaceURI === CII_NAMESPACE || 
         rootElement.lookupNamespaceURI('rsm') === CII_NAMESPACE)) {
      return true;
    }

    // Also check with getElementsByTagNameNS for completeness
    const ciiNodes = Array.from(doc.getElementsByTagNameNS(CII_NAMESPACE, 'CrossIndustryInvoice'))
      .concat(Array.from(doc.getElementsByTagName('rsm:CrossIndustryInvoice')));
    
    return ciiNodes.some(node => 
      node.namespaceURI === CII_NAMESPACE || 
      node.lookupNamespaceURI('rsm') === CII_NAMESPACE
    );
  }

  private static async getSchema(): Promise<string> {
    try {
      const response = await fetch('/xsd/xrechnung-semantic-model.xsd');
      if (!response.ok) {
        throw new Error('Failed to fetch schema');
      }
      return await response.text();
    } catch (error) {
      console.error('Error loading schema:', error);
      throw new Error('Schema loading failed');
    }
  }

  private static handleSchemaValidationError(error: any): ValidationError {
    const baseLocation = error?.err?.line ? `Line ${error.err.line}` : 'document';
    const baseMessage = error?.err?.msg || 'Unbekannter Validierungsfehler';
    
    if (error?.err?.code === 'InvalidFormat') {
      return this.createValidationError(
        'INVALID_FORMAT',
        baseMessage,
        baseLocation,
        'WARNING',
        error
      );
    }
    
    return this.createValidationError(
      'VALIDATION_ERROR',
      baseMessage,
      baseLocation,
      'CRITICAL',
      error
    );
  }

  private static createValidationError(
    code: ErrorCode,
    message: string,
    location: string,
    severity: 'WARNING' | 'CRITICAL',
    details?: unknown
  ): ValidationError {
    const error: ValidationError = {
      code,
      message,
      location,
      severity
    };

    if (details) {
      error.details = this.formatErrorDetails(details);
      error.suggestion = this.getSuggestionForError(code, details);
    }

    return error;
  }

  private static formatErrorDetails(details: unknown): string {
    if (details instanceof Error) {
      return details.message;
    }
    if (typeof details === 'object' && details !== null) {
      return JSON.stringify(details, null, 2);
    }
    return String(details);
  }

  private static getSuggestionForError(code: ErrorCode, details?: unknown): string {
    const suggestions: Record<ErrorCode, string> = {
      'PARSE_ERROR': 'Überprüfen Sie die XML-Syntax auf Gültigkeit',
      'INVALID_FORMAT': 'Stellen Sie sicher, dass alle Pflichtfelder korrekt formatiert sind',
      'VALIDATION_ERROR': 'Überprüfen Sie die Struktur gemäß XRechnung-Schema',
      'PROCESSING_ERROR': 'Versuchen Sie es erneut oder wenden Sie sich an den Support',
      'MISSING_REQUIRED': 'Fügen Sie die fehlenden Pflichtfelder hinzu',
      'INVALID_STRUCTURE': 'Überprüfen Sie die XML-Struktur',
      'MISSING_FIELD': 'Fügen Sie das fehlende Feld hinzu',
      'INVALID_VALUE': 'Korrigieren Sie den ungültigen Wert',
      'INVALID_ROOT': 'Überprüfen Sie das Root-Element',
      'INVALID_XRECHNUNG': 'Stellen Sie sicher, dass es sich um eine gültige XRechnung handelt',
      'MISSING_REQUIRED_FIELD': 'Fügen Sie das erforderliche Feld hinzu'
    };

    return suggestions[code] || 'Bitte überprüfen Sie die Eingabe';
  }

  static async validate(xmlContent: string): Promise<ValidationError[]> {
    const errors: ValidationError[] = [];
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlContent, 'application/xml');

    // Basic XML parsing validation
    const parseErrors = xmlDoc.getElementsByTagName("parsererror");
    if (parseErrors.length > 0) {
      errors.push(this.createValidationError(
        'PARSE_ERROR',
        'XML-Parsing fehlgeschlagen',
        'document',
        'CRITICAL',
        parseErrors[0].textContent
      ));
      return errors;
    }

    // Remove comments and normalize
    const normalizedDoc = this.normalizeXml(xmlDoc);

    // Validate document type
    if (!this.isValidXRechnung(normalizedDoc)) {
      errors.push(this.createValidationError(
        'INVALID_XRECHNUNG',
        'Die Datei entspricht nicht dem XRechnung-Format',
        'document',
        'CRITICAL',
        'Invalid root element or namespace'
      ));
      return errors;
    }

    // Continue with schema validation...
    try {
      const schemaContent = await this.getSchema();
      const validationResult = XMLValidator.validate(xmlContent, {
        allowBooleanAttributes: true,
        preserveOrder: true,
        validateXML: true,
        xsdFilePath: schemaContent
      });

      if (validationResult !== true) {
        const schemaErrors = Array.isArray(validationResult) ? validationResult : [validationResult];
        schemaErrors.forEach(error => {
          errors.push(this.handleSchemaValidationError(error));
        });
      }
    } catch (error) {
      errors.push(this.createValidationError(
        'PROCESSING_ERROR',
        'Schema-Validierung fehlgeschlagen',
        'document',
        'CRITICAL',
        error
      ));
    }

    return errors;
  }
} 