import React, { useEffect, useState } from 'react';
import { FileUpload, InvoiceDisplay, ValidationResults, Footer } from './components/exports';
import { XRechnungData } from './types/xrechnung';
import { ValidationResult } from './types/validation';
import { useTranslation } from './contexts/TranslationContext';
import { FileCheck } from 'lucide-react';
import { Helmet } from 'react-helmet';
import CookieConsent from 'react-cookie-consent';
import { XRechnungInfo } from './components/XRechnungInfo';
import { Routes, Route, Link } from 'react-router-dom';
import { Layout } from './components/Layout';
import { NotFound } from './components/NotFound';
import { SEO } from './components/SEO';

function App() {
  const [file, setFile] = useState<File | null>(null);
  const [validationStatus, setValidationStatus] = useState<'idle' | 'validating' | 'validated' | 'error'>('idle');
  const [invoice, setInvoice] = useState<XRechnungData | null>(null);
  const [validationResult, setValidationResult] = useState<ValidationResult | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    // Track page view
    if (window.gtag) {
      window.gtag('config', 'G-CYZX68VXPN', {
        page_path: window.location.pathname,
      });
    }
  }, []);

  const handleFileUpload = async (data: XRechnungData, validation: ValidationResult) => {
    setValidationStatus('validating');
    
    try {
      // Always set the invoice data if we have it
      if (data) {
        setInvoice(data);
      }

      // Set validation result
      setValidationResult(validation);
      
      // Update status based on validation
      if (!validation.isValid) {
        setValidationStatus('error');
      } else {
        setValidationStatus('validated');
      }
    } catch (error) {
      console.error('Error processing file:', error);
      setValidationStatus('error');
      setValidationResult({
        isValid: false,
        errors: [{
          code: 'PROCESSING_ERROR',
          message: 'Ein unerwarteter Fehler ist aufgetreten',
          location: 'document',
          severity: 'CRITICAL'
        }],
        warnings: []
      });
    }
  };

  const handleReset = () => {
    setFile(null);
    setValidationStatus('idle');
    setInvoice(null);
    setValidationResult(null);
  };

  return (
    <Layout>
      <Routes>
        <Route path="/" element={
          <div className="space-y-8">
            <SEO 
              title="XRechnung Viewer - Kostenlos XRechnungen anzeigen & validieren"
              description="Laden Sie Ihre XRechnung hoch und prüfen Sie sie kostenlos mit unserem XRechnung Viewer. Unterstützt XRechnung, ZUGFeRD und andere E-Rechnungsformate."
              canonicalUrl="https://xrechnungzentrale.de"
              type="website"
            />
            <section className="bg-white rounded-lg shadow-sm p-6">
              <div className="max-w-3xl mx-auto">
                <div className="flex flex-col items-center mb-6">
                  <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-6">Kostenloser XRechnung Viewer – Einfach und Effizient</h1>
                  <p className="text-lg text-gray-600 max-w-3xl mx-auto">Laden Sie Ihre XRechnung hoch, um sie schnell anzuzeigen, zu validieren und zu prüfen – kostenlos und ohne Anmeldung. Unterstützt das EN-16931-Format.</p>
                  {validationResult && (
                    <button
                      onClick={handleReset}
                      className="mt-8 px-4 py-2 text-sm font-medium text-blue-600 bg-blue-50 rounded-md hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      weitere XRechnung hochladen
                    </button>
                  )}
                </div>
                {!validationResult && <FileUpload onFileProcessed={handleFileUpload} />}
              </div>
            </section>
            <CookieConsent
              location="bottom"
              buttonText="Zustimmen"
              cookieName="XRechnung Viewer Cookies"
              style={{ background: "#1F2937", color: "#FFFFFF", borderTop: "1px solid #4B5563" }}
              buttonStyle={{ color: "#FFFFFF", backgroundColor: "#3B82F6", fontSize: "14px", padding: "10px 20px", borderRadius: "5px", transition: "background-color 0.3s" }}
              declineButtonText="Ablehnen"
              enableDeclineButton
              onDecline={() => {
                console.log("Cookies abgelehnt");
              }}
              buttonWrapperClasses="flex justify-center"
            >
              Diese Website verwendet Cookies, um Ihnen das beste Erlebnis zu bieten.  
              <a href="/datenschutz" style={{ color: "#3B82F6", textDecoration: "underline" }}>Mehr erfahren</a>
            </CookieConsent>

            {validationStatus === 'validating' && (
              <div className="flex items-center justify-center p-4 bg-blue-50 rounded-lg">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-blue-600 mr-3"></div>
                <span className="text-blue-600">{t('processingFile')}</span>
              </div>
            )}
            {invoice && validationResult && (
              <div className="lg:flex lg:gap-4">
                <div className="lg:w-3/4 mb-8 lg:mb-0">
                  <InvoiceDisplay invoice={invoice} />
                </div>
                <div className="lg:w-1/4 w-full">
                  <ValidationResults result={validationResult} />
                </div>
              </div>
            )}
            <section className="py-16 bg-white rounded-xl shadow-sm">
              <div className="max-w-4xl mx-auto px-4">
                <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
                  Automatisieren Sie Ihre Rechnungsverarbeitung
                </h2>
                <div className="grid md:grid-cols-3 gap-8">
                  <div className="text-center">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      className="lucide lucide-credit-card w-12 h-12 text-blue-500 mx-auto mb-4"
                    >
                      <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                      <line x1="2" x2="22" y1="10" y2="10"></line>
                    </svg>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">
                      Rechnungen direkt bezahlen
                    </h3>
                    <p className="text-gray-600">
                      Automatisieren Sie Ihre Zahlungsprozesse und sparen Sie wertvolle Zeit.
                    </p>
                  </div>
                  <div className="text-center">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      className="lucide lucide-users w-12 h-12 text-blue-500 mx-auto mb-4"
                    >
                      <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">
                      Steuerberater informieren
                    </h3>
                    <p className="text-gray-600">
                      Nahtlose Integration mit Ihrem Buchhaltungsteam für effiziente Zusammenarbeit.
                    </p>
                  </div>
                  <div className="text-center">
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      className="lucide lucide-clipboard-list w-12 h-12 text-blue-500 mx-auto mb-4"
                    >
                      <rect width="8" height="4" x="8" y="2" rx="1" ry="1"></rect>
                      <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                      <path d="M12 11h4"></path>
                      <path d="M12 16h4"></path>
                      <path d="M8 11h.01"></path>
                      <path d="M8 16h.01"></path>
                    </svg>
                    <h3 className="text-xl font-semibold text-gray-800 mb-2">
                      Bestellungen verwalten
                    </h3>
                    <p className="text-gray-600">
                      Verwalten Sie Ihre Angebote, Bestellungen, Projekte, Aufträge und Rechnungen in einem System.
                    </p>
                  </div>
                </div>

                <div className="mt-12 text-center">
                  <a 
                    href="https://robaws.com/de" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="inline-flex items-center gap-2 bg-blue-600 hover:bg-blue-700 text-white font-medium px-8 py-3 rounded-lg transition-colors"
                  >
                    Jetzt Robaws entdecken
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      stroke="currentColor" 
                      strokeWidth="2" 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      className="lucide lucide-arrow-right w-5 h-5"
                    >
                      <path d="M5 12h14"></path>
                      <path d="m12 5 7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </section>
          </div>
        } />
        <Route path="/xrechnung-info" element={<XRechnungInfo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
}

export default App;