import { XRechnungData, ValidationResult } from '../types';

export type XRechnungFormat = 'UBL' | 'CII';

export abstract class BaseXRechnungParser {
  protected xmlDoc: Document;
  protected namespaces: {[key: string]: string};

  constructor(xmlContent: string) {
    const parser = new DOMParser();
    this.xmlDoc = parser.parseFromString(xmlContent, 'application/xml');
    this.namespaces = this.extractNamespaces();
  }

  abstract parse(): XRechnungData;
  abstract validate(): ValidationResult;

  protected extractNamespaces(): {[key: string]: string} {
    const root = this.xmlDoc.documentElement;
    const namespaces: {[key: string]: string} = {};
    
    Array.from(root.attributes).forEach(attr => {
      if (attr.name.startsWith('xmlns:')) {
        const prefix = attr.name.split(':')[1];
        namespaces[prefix] = attr.value;
      } else if (attr.name === 'xmlns') {
        namespaces['default'] = attr.value;
      }
    });
    
    return namespaces;
  }

  static detectFormat(xmlContent: string): XRechnungFormat {
    const parser = new DOMParser();
    const doc = parser.parseFromString(xmlContent, 'application/xml');
    const root = doc.documentElement;

    if (root.tagName === 'rsm:CrossIndustryInvoice' || 
        root.namespaceURI?.includes('uncefact')) {
      return 'CII';
    }
    
    if (root.tagName.includes('Invoice') || 
        root.tagName.includes('CreditNote')) {
      return 'UBL';
    }

    throw new Error('Unknown XRechnung format');
  }

  protected getElementValue(path: string, node: Element = this.xmlDoc.documentElement): string {
    const parts = path.split('/');
    let current = node;
    
    for (const part of parts) {
      const element = current.getElementsByTagName(part)[0];
      if (!element) return '';
      current = element;
    }
    
    return current.textContent || '';
  }
}
