import React from 'react';
import { Link } from 'react-router-dom';
import { FileCheck } from 'lucide-react';
import { Footer } from './exports';
import { Navigation } from './Navigation';

export const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm relative">
        <div className="max-w-7xl mx-auto px-4 py-4 sm:px-6 lg:px-8 flex items-center justify-between">
          <Link to="/" className="flex items-center space-x-3">
            <FileCheck className="h-8 w-8 text-blue-600" />
            <h1 className="text-2xl font-semibold text-gray-900">XRechnung Viewer</h1>
          </Link>
          <Navigation />
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 py-8 sm:px-6 lg:px-8">
        {children}
      </main>

      <Footer />
    </div>
  );
};
